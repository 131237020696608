import React from 'react'

function ProfileInfo() {

    return (
        <div>

            <form onSubmit={null}>

                <label className='admin-login-title'>Admin Login</label>

                <div style={{ display: "flex", flexDirection: "row" }}>
                    <input required type={"text"} placeholder={"Full Name"} inputMode="email"
                        value={""} onChange={(e) => { }}
                    />

                    <input required type={"text"} placeholder={"Nationality"}
                        value={""} onChange={(e) => { }}
                    />
                </div>

                {/* {error && <Alert variant="danger">{error}</Alert>} */}

                <button className='mi-button'>Login</button>
            </form>

        </div>
    )
}

export default ProfileInfo