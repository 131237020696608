import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { serverTimestamp, getFirestore, addDoc, collection } from 'firebase/firestore'
import config from "../../utils/Config";

const CONTACTS_COLLECTION = 'jahanzaib-portfolio/contact-queries/requests'

// Initialize Firebase
const app = initializeApp(config.firebaseConfig)

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app)

export const auth = getAuth()

export const loginAdmin = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
}

export const submitGetInTouchForm = async (contactData, onSuccess, onFailure) => {
    try {
        contactData.createdAt = serverTimestamp()
        const docRef = await addDoc(collection(db, CONTACTS_COLLECTION), contactData)
        console.log("Form submitted", docRef.id)
        onSuccess && onSuccess()
    } catch (error) {
        console.log(error)
        onFailure && onFailure()
    }
}