import React from 'react'
import config from '../utils/Config'
import { Helmet } from "react-helmet";

export default function TabHeader({ pageName, metaDescription }) {
    return (
        <Helmet>
            <title>{`${pageName} - ${config.ownerName}`}</title>
            <meta
                name="description"
                content={`${config.ownerName} ${metaDescription}`}
            />
        </Helmet>
    )
}
