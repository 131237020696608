import React, { useContext, useState, useEffect } from "react"
import { auth, loginAdmin } from "../data/firebase/FBHelper"

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    async function login(email, password, onSuccess, onFailure) {
        try {
            const user = await loginAdmin(email, password)
            onSuccess(user.user)
        } catch (error) {
            // console.log(error)
            let errormsg = "Unable to sign in, please try later"

            if (error.code == "auth/invalid-email") {
                errormsg = "Please type a valid email address"
            } else if (error.code == "auth/wrong-password") {
                errormsg = "Invalid email or password"
            } else if (error.code == "auth/user-not-found") {
                errormsg = "No account exist with that email"
            }

            onFailure(errormsg)
        }
    }

    function logout() {
        return auth.signOut()
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscribe
    }, [])

    const value = {
        currentUser,
        login,
        logout
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}