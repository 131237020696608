import '../../scss/admin/index.css'
import React, { useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../contexts/AuthContext'

function Login() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const { login, currentUser } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (currentUser) {
            console.log("Alreday logged In", currentUser.email)
            navigate("Dashboard")
        }
    }, [])

    return (
        <div className='admin' style={{ flexDirection: "column" }}>

            <form onSubmit={handleOnLogin}>
                <label className='admin-login-title'>Admin Login</label>

                <input required type={"text"} placeholder={"Email"} inputMode="email"
                    value={email} onChange={(e) => setEmail(e.target.value)}
                />

                <input required type={"password"} placeholder={"Password"}
                    value={password} onChange={(e) => setPassword(e.target.value)}
                />

                {error && <Alert variant="danger">{error}</Alert>}

                <button className='mi-button'>Login</button>
            </form>

        </div>
    )

    function handleOnLogin(e) {
        e.preventDefault()

        setError("")
        login(email, password, (userData) => {
            navigate("Dashboard")
        }, (errorMsg) => {
            setError(errorMsg)
        })
    }
}

export default Login