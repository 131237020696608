import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import Sidebar from './main_menu/Sidebar'
import { Outlet } from "react-router-dom";
import ProfileInfo from './main_menu/ProfileInfo';

function Dashboard() {

    const { logout, currentUser } = useAuth()
    const navigate = useNavigate()

    return (
        <div style={{
            padding: '50px 0px 0px 370px'
        }}>
            <Sidebar />

            <ProfileInfo />

        </div>
    )

    async function handleOnLogout() {
        await logout()
        navigate("/admin")
    }
}

export default Dashboard