import Mock from "../mock";

const database = {
  information: {
    name: 'Jahanzaib Ramzan',
    aboutContent: "Experienced mobile app developer who has a track record of success creating apps that are both well-received and commercially viable. Skilled with working as a team and incorporating input into projects.",
    age: 0,
    phone: '',
    nationality: 'Pakistan',
    language: 'English, Urdu',
    email: '',
    address: 'Thali Chowk By Pass Road, Rahim Yar Khan, Pakistan',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: 'https://www.facebook.com/jahanzaib.ramzan.7',
      twitter: 'https://twitter.com/JahanzaibRamzan',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/jahanzaibramzan/',
      dribbble: '',
      github: ''
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/my_resume.pdf'
  },
  services: [
    // {
    //   title: "Web Design",
    //   icon: 'color-pallet',
    //   details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    // },
    // {
    //   title: "Web Development",
    //   icon: 'code',
    //   details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    // },
    {
      title: "React Native App Development",
      icon: 'mobile',
      details: "I have great experience of working on a React Native project. I have the proficiency to write clean and scalable code which will enhance the overall app performance."
    }
  ],
  reviews: [
    // {
    //   id: 1,
    //   content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
    //   author: {
    //     name: 'Burdette Turner',
    //     designation: 'Web Developer, Abc Company'
    //   }
    // },
  ],

  skills: [
    {
      title: "React Native",
      value: 90
    },
    {
      title: "Android",
      value: 70
    },
    {
      title: "Firebase",
      value: 80
    },
    {
      title: "Redux",
      value: 70
    },
    {
      title: "REST API",
      value: 90
    },
    {
      title: "Google Maps / Geo Location API",
      value: 85
    }

  ],

  portfolios: [
    {
      id: 1,
      title: "DineHome",
      subtitle: "Food Delivery System (NORWAY)",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://play.google.com/store/apps/details?id=no.dinehome.customerapp'
    },
    {
      id: 2,
      title: "eQSmart",
      subtitle: "Quality Assurance System (USA)",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://play.google.com/store/apps/details?id=com.qaproject.lantix'
    },
    {
      id: 3,
      title: "Splintr",
      subtitle: "Buy Now Pay Later System (UAE)",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://www.splintr.com/'
    },
    {
      id: 4,
      title: "Parle",
      subtitle: "Social Media Platform (USA)",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: ''
    },
    {
      id: 5,
      title: "ClickTime",
      subtitle: "Employee Timesheet Management System (Australia)",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: ''
    },
  ],

  experience: {
    workingExperience: [
      {
        id: 1,
        year: "Feb 2018 - Present",
        position: "Sr. Mobile App Developer",
        company: "Hello World Technologies, Rahim Yar Khan",
        details: "Developing mobile apps (Android + IPhone) with React Native Platform. Leading mobile app team to work in a Agile process development"
      },
      {
        id: 2,
        year: "Dec 2016 - Jan 2018",
        position: "Android Developer",
        company: "Hello World Technologies, Rahim Yar Khan",
        details: "Design layouts in XML and backend code in Java language. Worked on di erent 3rd party API (GoogleMaps, Firebase, REST, etc). Successfully delivered 3+ apps for the multiple companies. Client requirements gathering and implementing the solution for them"
      },
      {
        id: 3,
        year: "Jul 2016 - Dec 2016",
        position: "Jr. Android Developer",
        company: "Jotana Technologies, Lahore",
        details: "I worked as junior android mobile app developer. My major responsibility there was to design layouts and also handle the backend coding in Java language."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "Jan 2012 - Jun 2016",
        graduation: "BS Computer Science",
        university: "The Islamia University of Bahawalpur",
        details: "Rahim Yar Khan, Pakistan"
      },
      {
        id: 2,
        year: "Jan 2010 - Jan 2012",
        graduation: "F.Sc Pre-Engineering",
        university: "NICAAS Group of Colleges",
        details: "Rahim Yar Khan, Pakistan"
      }
    ]
  },

  blogs: [
    // {
    //   id: 1,
    //   title: 'Markdown & Html supported blog.',
    //   featuredImage: '/images/blog-image-1.jpg',
    //   filesource: '../../blog/markdown-html-supported-blog.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
  ],

  contactInfo: {
    phoneNumbers: ['+92-308-7636-372'],
    emailAddress: ['jahanzaibramzan@gmail.com'],
    address: "Rahim Yar Khan, Pakistan"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});